@import url('https://fonts.googleapis.com/css?family=Lato:300');

@font-face {
    font-family: "muna";
    src: url('../font/Muna.ttf');
    src: url('../font/Muna.ttf') format('truetype');
    src: url('../font/Muna2.ttc');
}

@font-face {
  font-family: "B Arabic Style";
    src: url('../font/BArabics.ttf');
    src: url('../font/BArabics.ttf') format('truetype');
}

@font-face {
  font-family: "Geeza Pro";
    src: url('../font/GeezaPro.ttc');
    src: url('../font/GeezaPro.ttc') format('truetype');
}

body{
    font-family: "Muna";
    background: #ffffff;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100%;
    -webkit-appearance:none;
}

a:focus, a:hover{
  text-decoration: none;
}

.header{
    padding: 5px 0px 0px 0px;
}

.header img{
      padding-top: 9px;

}

.header span{
    font-size: 50px;
    color: #ffffff;
}

#weather p{
  font-size: 45px;
  margin-bottom: -50px;
  margin-left: -5px;
}

.header h2{
    color: #ffffff;
    margin: -30px 0px 0px 0px;
}
.allatif {
    margin-top: 8%;
}
.allatif h1{
    color: #ffffff;
    font-size: 60px;
    font-family: "B Arabic Style";
    margin-bottom: 30px;
    margin-top: 5%;
}

.allatif img{
    width: 200px;
}

.allatif .txt{
    padding-left: 3em;
    margin-top: -5px;
}



.allatif h2{
    color: #fbbe57;
    font-weight: bold;
    padding-top: 25px;
}

.tawasal{
    margin: 25px 0px 0px 0px;
}

.btn-primary{
    background-color: #fbbe57;
    color: #ffffff;
    border-color: #fbbe57;
}

.btn-primary:hover{
    background-color: #fbbe57;
    color: #ffffff;
    border-color: #fbbe57;
}

/*footer .copyright img{
  float: right;
}*/

.copyright img{
  -webkit-margin-before: 150px;
}



@media only screen and (max-width: 479px)  {
	html{
		height: 100%;
	}
	
    body{
        /*background-image: url("../images/bgImage@3-x.png");*/
        background-repeat: no-repeat;
        background-size:100% auto;
        back
        height: 100%;
    }

    .header span{
      font-size: 40px;
      padding-top: 5px;
    }

    .header img{
      width: 90px;
    }
    .allatif {
        margin-top: 18%;
    }
    .allatif img{
        width: 200px;
        margin-top: 10%;
    }

    .allatif h1{
        margin-top: 20%;
        font-size: 40px;
    }

    .allatif h2{
        margin-top: -0.5em;
    }

    .tawasal{
        margin: 35px 0px 0px 0px;
    }

    .tawasal img{
        width: 100px;
        margin-bottom: 20px;
    }

    footer .copyright{
      padding-bottom: 15px;
      text-align: center;
    }
    
    .copyright img{
		-webkit-margin-before: 60px;
	}


}

@media only screen and (max-width: 320px)  {
    .allatif img{
        width: 170px;
        margin-top: 10%;
    }
}
@media (max-height: 750px)  {
    .allatif {
        margin-top: 3%;
    }
}
/*@media only screen and (min-width: 1450px) and (max-width: 1690px)  {
    .allatif {
        margin-top: 10%;
    }
}*/
@media only screen and (min-width: 1700px)   {
    .allatif {
        position: absolute;
        top: 25%;
        width: 100%;
        margin: auto;
    }
    .allatif img {
        width: 300px;
    }

}
/*@media screen and (min-width: 1440px){
    .allatif{
        margin-top: 140px;
    }
}
@media screen and (min-width: 1680px){
    .allatif{
        margin-top: 200px;
    }
}
 @media screen and (min-width: 1920px) {
    
    .allatif{
            margin-top: 280px;
        }

}
@media screen and (min-width: 1025px) and (max-width: 1280px) {
 .allatif{
            margin-top: 80px;
        }
}*/
